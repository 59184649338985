import { IconType } from 'gantri-components/dist/components/icon/icon.type';
import { MachineStatusBadgeDef } from './machine-status-badge.types';
import { machineStatuses } from '../../../../constants/machines';
import { StatusBadge } from '../../../../components/status-badge';
import { statusBadgeThemes } from '../../../../components/status-badge/status-badge.constants';

export const MachineStatusBadge = (props: MachineStatusBadgeDef) => {
  const { hideIcon, status } = props;

  const getBadgeIcon = (iconName: IconType) => {
    return hideIcon ? undefined : iconName;
  };

  switch (status) {
    case machineStatuses.ready: {
      return (
        <StatusBadge
          icon={getBadgeIcon('actions:thumbs_up')}
          text={status}
          theme={statusBadgeThemes.greenLight}
        />
      );
    }

    case machineStatuses.manual: {
      return (
        <StatusBadge
          icon={getBadgeIcon('people:person')}
          text={status}
          theme={statusBadgeThemes.blueLight}
        />
      );
    }

    case machineStatuses.inProgress: {
      return (
        <StatusBadge
          icon={getBadgeIcon('time:clock')}
          text={status}
          theme={statusBadgeThemes.yellowLight}
        />
      );
    }

    case machineStatuses.offline: {
      return (
        <StatusBadge
          icon={getBadgeIcon('ui-control:x')}
          text={status}
          theme={statusBadgeThemes.redLight}
        />
      );
    }

    case machineStatuses.archived: {
      return (
        <StatusBadge
          icon={getBadgeIcon('ui-control:minus_circle')}
          text={status}
          theme={statusBadgeThemes.monochromeLight}
        />
      );
    }

    default:
      return <StatusBadge text={status} theme={statusBadgeThemes.redLight} />;
  }
};
